<template>
  <div class="buy-miles pd-20">
    <a-page-header class="mb-10 pd-0" sub-title="" @back="$router.go(-1)">
      <div slot="title">
        <h1>Editar Cliente - ID {{ customers.details.id }}</h1>
      </div>

      <div v-if="customers.details.user != undefined" slot="extra">
        <a-icon class="mr-5" type="user" />
        <b
          >{{ customers.details.user.first_name }}
          {{ customers.details.user.last_name }}
        </b>
      </div>
    </a-page-header>

    <a-form
      class="form-contracts"
      @submit.prevent="submitCustomer"
      :form="customerForm"
    >
      <a-row :gutter="[20, 20]">
        <a-col class="box box-contractor" :span="8">
          <a-row :gutter="[20, 0]">
            <a-row class="title-box mb-20" type="flex" justify="space-between">
              <a-col>
                <h3>
                  <a-icon class="mr-5" type="user" />
                  DADOS DO CLIENTE
                </h3>
              </a-col>
            </a-row>

            <CustomerForm
              :customer="customers.details"
              :customerForm="customerForm"
            />
            <a-col style="display: none" class="a-center mt-10" :span="24">
              <a-button
                icon="user"
                type="primary"
                :loading="processingCustomer"
                html-type="submit"
              >
                Cadastrar cliente
              </a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col class="box box-services" :span="16">
          <a-row :gutter="[0, 0]">
            <h2 class="title mb-10">
              <a-row type="flex" justify="space-between" align="middle">
                <a-col> ... </a-col>
                <a-col v-show="customers.details"> </a-col>
              </a-row>
            </h2>
          </a-row>
        </a-col>
      </a-row>

      <a-row type="flex" justify="end">
        <a-col>
          <a-button
            size="large"
            type="primary"
            class="mt-20 mr-10"
            :loading="loadingSubmitCustomer"
            html-type="submit"
          >
            SALVAR ALTERAÇÕES
          </a-button>
        </a-col>
      </a-row>
    </a-form>

    <pre
      id="live-debug"
      class="mt-40"
      style="display: none; height: 300px; width: 100%; overflow: auto"
    >
   {{ customers.details }} </pre
    >
  </div>
</template>

<script>
import customerMixins from "@/mixins/customers/customerMixins";
import CustomerForm from "@/components/customers/forms/CustomerForm.vue";

export default {
  components: {
    CustomerForm,
  },
  mixins: [customerMixins],
  beforeMount() {
    this.getCustomer(this.$route.params.id);
  },
  data() {
    return {
      customerForm: this.$form.createForm(this),
      loadingSubmitCustomer: false,
      processingCustomer: false,
    };
  },
  methods: {
    submitCustomer() {
      this.customerForm.validateFields(async (err, customer) => {
        console.log(err, customer);
        if (!err) {
          customer.id = this.$route.params.id;
          customer.user_id = this.customers.details.user.id;
          customer.status = this.customers.details.status;
          customer.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.loadingSubmitCustomer = true;

          this.$http
            .post("/customer/update", customer)
            .then(({ data }) => {
              this.$message.success(data.message);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            })
            .finally(() => {
              this.loadingSubmitCustomer = false;
            });
        }
      });
    },
  },
};
</script>